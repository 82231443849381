@keyframes spinner3 {
    to {
        transform: rotate(360deg);
    }
}

button.irin-spinner {
    height: 140px;
    width: 140px;
    background: rgba(212, 194, 194, 0.2);
    border-radius: 50% !important;
    border: 2px solid rgba(0, 0, 0, 0.4);
    border-top-color: #fff;
    border-bottom-color: #fff;
    animation: spinner3 400ms ease infinite;
}

.spinner-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.comment-like-section {
  width: 90%;
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
  height: 50px;
  place-content: flex-end;
  place-items: center;

  .like-button {
    display: inline-flex;
    align-items: center;
    position: relative;
    user-select: none;
  }

  .like-button img {
    width: 26px;
    height: 26px;
    cursor: pointer;
  }
}

.like-count {
  font-size: 12px;
  color: #222121;
  margin-left: 4px;
  margin-top: 14px;
  opacity: .7;
  font-style: bold;
  font-weight: 900;
}
