.books-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    max-width: 1000px;
}

.books-top-container {
    display: flex;
    flex-direction: column;
    place-content: center;
    place-items: center;
    padding-top: 10vh;
}

.books-top-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15vh;
    margin-bottom: 5vh;
}

@media only screen and (min-width: 1150px) {
    .book-item {
        flex-direction: column;
    }
}

@media only screen and (max-width: 1000px) {
    .books-container {
        margin-top: 15vh;
    }
}

@media only screen and (max-width: 800px) {
    .book-item {
        flex-direction: column;
    }

    .book-image {
        align-self: center;
    }

    .book-text {
        align-self: center;
    }
}

.book-item {
    display: flex;
    // gap: 1rem;
}

.book-item-header {
    //width: 100%;
    display: flex;
    justify-content: center;
    h3 {
      max-width: 200px;
      text-align: center;
    }
}

.book-text {
    max-width: 400px;
    padding: 2rem;
    h3 {
        margin-bottom: 2rem;
    }
}

.irin-btn {
    padding: 1rem 1rem;
    margin: 0 1rem;
    transition: all 0.5s ease;
    color: #41403e;
    font-size: 1.1rem;
    letter-spacing: 1px;
    outline: none;
    box-shadow: 20px 38px 34px -26px hsla(0, 0%, 0%, 0.2);
    border: 1px solid transparent;
    border-radius: 100px;
    background: white;
    background-clip: padding-box;

    &:hover {
        box-shadow: 2px 8px 4px -6px hsla(0, 0%, 0%, 0.3);
    }
}

.irin-btn::after {
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.7665266790309874) 75%, rgba(255, 255, 255, 1) 100%);
    content: "";
    z-index: -1;
    border-radius: 100px;
}

.close-options:after {
    display: inline-block;
    content: "\00d7"; /* This will render the 'X' */
    font-size: 2rem;
    padding: 10px;
}

.shop-link {
    color: #41403e;
}

.book-item {
    min-height: 360px;
    outline: none;
    box-shadow: 20px 38px 34px -26px hsla(0, 0%, 0%, 0.2);
    border: 1px solid transparent;
    border-radius: 10px;
    background: white;
    background-clip: padding-box;
    transition: all 0.5s ease;
    padding: 1rem 1rem;
    margin: 1rem;
    position: relative;
    &::after {
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: -1px;
        right: -1px;
        background: radial-gradient(circle, rgba(0, 0, 0, 0.7665266790309874) 75%, rgba(255, 255, 255, 1) 100%);
        content: "";
        z-index: -1;
        border-radius: 10px;
    }
    &.category:hover {
        box-shadow: 2px 8px 4px -6px hsla(0, 0%, 0%, 0.3);
        cursor: pointer;
    }
}

.book-text {
    display: flex;
    flex-direction: column;
}

.book-buttons-container {
    align-self: flex-end;
    margin-top: auto;
    display: flex;
    flex-flow: row nowrap;
    a {
        margin: 1rem;
        color: #41403e;
        outline: none;
        box-shadow: 20px 38px 34px -26px hsla(0, 0%, 0%, 0.2);
        border: 1px solid transparent;
        border-radius: 50px;
        background: white;
        background-clip: padding-box;
    }
}

.read-container {
    padding-top: 2rem;
    display: block;
}

.read-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    i {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}

.read-content {
    display: flex;
    flex-direction: column;
    max-width: 700px;
}

.book-item {
    cursor: pointer;
}

.book-single-container {
    display: flex;
    flex-direction: column;
    place-items: center;
    padding: 25px;
    text-align: justify;
    min-height: 100vh;
    padding-top: 15vh;
    gap: 6vh;
}

.book-banner {
    min-height: 300px;
    min-width: fit-content;
    width: 90vw;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.stories-banner {
    min-height: 150px;
    width: 90vw;
    max-width: 700px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 25px;
    margin-top: 50px;
}

.stories-annonce {
    width: 90vw;
    max-width: 600px;
    text-align: center;
}

.single-story-img-min {
    border-radius: 15px;
}

#foxBanner {
    background-image: url("../assets/book-hats/03.png");
}

#foxBannerEng {
    background-image: url("../assets/book-hats/03eng.png");
}

#kamillaBanner {
    background-image: url("../assets/book-hats/04.png");
}

#kamillaBannerEng {
  background-image: url("../assets/book-hats/04eng.png");
}

#ultramarinBanner {
  background-image: url("../assets/book-hats/ultramarin-book.png");
}

#ultramarinBannerEng {
  background-image: url("../assets/book-hats/05eng.png");
}

#tryapkaBanner {
  background-image: url("../assets/book-hats/tryapka-book.png");
}

#tryapkaBannerEng {
  background-image: url("../assets/book-hats/the-soft-rag.png");
}

#neeshBanner {
  background-image: url("../assets/book-hats/neesh-book.png");
}

#neeshBannerEng {
  background-image: url("../assets/book-hats/do-not-eat-me.png");
}

#eternityBanner {
  background-image: url("../assets/book-hats/06.png");
}

#eternityBannerEng {
  background-image: url("../assets/book-hats/eternityBookHat.png");
}

#howToSaveParentsBanner {
    background-image: url("../assets/book-hats/02.png");
}

#howToSaveParentsBannerEng {
  background-image: url("../assets/book-hats/02eng.png");
}

#howToFixEmtinessBanner {
    background-image: url("../assets/book-hats/01.png");
}

#howToFixEmtinessBannerEng {
  background-image: url("../assets/book-hats/01eng.png");
}

#howToFixEmtinessStoriesBanner {
    background-image: url("../assets/book-hats/stories_hat.png");
}

.book-about {
    width: 100%;
    max-width: 750px;
    padding-inline: 20px;
}

.book-trailer {
    iframe {
        width: 700px;
        min-height: 350px;
        max-width: 80vw;
    }
}

.book-bottom {
    display: flex;
    justify-content: center;
}

.litres-link {
    display: flex;
    flex-direction: column;
    place-content: center;
    place-items: center;
    text-decoration: none;
}
