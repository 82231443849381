@import "./styles/custom";
@import "./styles/books";
@import url('https://fonts.cdnfonts.com/css/xarrovv');

@font-face {
  font-family: 'Xarrovv';
  font-style: normal;
  font-weight: normal;
  src: local('Xarrovv'), url('https://fonts.cdnfonts.com/s/27141/Xarrovv.woff') format('woff');
}

* {
  font-family: 'Xarrovv';
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}


$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 18px;

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

body::-webkit-scrollbar {
  width: 0.25rem;
}

body::-webkit-scrollbar-track {
  background: #e4eeda;
}

body::-webkit-scrollbar-thumb {
  background: rgba(42, 42, 42, 0.81);
}

body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
}

app-root {
  display: block;
  height: 100%;
  overflow-x: hidden;
}

::selection {
  background: #70a7bd;
}
